import React, { FC, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import styles from './ApplicationRightPanel.module.css';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as EditIcon } from '../../icons/Edit.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as GoOverIcon } from '../../icons/GoOver.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import {
  TApplication,
  useDeleteApplicationMutation,
  useGetApplicationByIdQuery,
  useLazyDeleteSessionByClientQuery,
  useLazyGetScopesQuery,
  useLazyGetUserCountQuery,
} from '../../redux/services/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { RootState } from '../../redux/store';
import { getImageURL, isEditor, isOwner } from '../../helpers';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ApplicationRightPanelUsers } from './ApplicationRightPanelUsers';
import { connect, useDispatch } from 'react-redux';
import {
  TAppSlice,
  setApplicationInfoTab,
  setApplicationRightPanelTab,
} from '../../redux/appSlice';
import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, Popover, Tooltip, Modal } from '@mui/material';
import { ResizablePanel } from './ResizablePanel';
import { ApplicationRightPanelProfile } from './ApplicationRightPanelProfile';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { PROJECT_NAME } from '../../constants';

type TApplicationRightPanel = {
  variant?: string;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  selectedClientId?: TAppSlice['selectedClientId'];
  isMobile: TAppSlice['isMobile'];
  userId?: string;
  globalRole?: string;
  applicationRightPanelTab: number;
};

const mapStateToProps = (state: RootState) => ({
  isClientPanelOpen: state.app.isClientPanelOpen,
  selectedClientId: state.app.selectedClientId,
  userId: state.user.userProfile.id,
  globalRole: state.user.userProfile.role,
  isMobile: state.app.isMobile,
  applicationRightPanelTab: state.app.applicationRightPanelTab,
});

const ApplicationRightPanelComponent: FC<TApplicationRightPanel> = ({
  variant,
  isClientPanelOpen,
  selectedClientId,
  userId,
  isMobile,
  applicationRightPanelTab,
  globalRole,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isPopoverOpen = Boolean(anchorEl);
  const [deleteApplication] = useDeleteApplicationMutation();
  const [deleteSessionByClient] = useLazyDeleteSessionByClientQuery();
  const dispatch = useDispatch();
  const [scopesData, setScopesData] = useState<
    { scopes?: string; createdAt?: string } | undefined
  >();

  const hidden = isMobile || isClientPanelOpen;
  const { data: application } = useGetApplicationByIdQuery(
    { client_id: selectedClientId || '', user_id: userId || '' },
    {
      skip: !selectedClientId || !userId,
    },
  );
  const client = application?.client;
  const [getScopes] = useLazyGetScopesQuery();

  useEffect(() => {
    const setScopes = async () => {
      if (userId && selectedClientId)
        setScopesData((await getScopes({ userId, clientId: selectedClientId })).data);
    };
    setScopes();
  }, [userId, selectedClientId]);

  const handleDeleteSessionByClient = async (userId: string, clientId: string) => {
    await deleteSessionByClient({ userId: userId, clientId: clientId });
    window.location.reload();
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteApp = async () => {
    if (selectedClientId) {
      await deleteApplication(selectedClientId);
      setDeleteModalOpen(false);
      setAnchorEl(null);
    }
  };

  const handleClosePopover = () => setAnchorEl(null);
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const panelRef = useRef<HTMLDivElement>(null);

  if (!selectedClientId) {
    return (
      <ResizablePanel>
        <div className={clsx(styles.panel, { [styles['panel-hidden']]: hidden })}>
          <div className={styles['panel-empty']}>
            <Typography className={clsx('text-17-regular', 'font-golos', 'color-858BA0')}>
              Приложения не выбраны
            </Typography>
          </div>
        </div>
      </ResizablePanel>
    );
  } else {
    return (
      <ResizablePanel>
        <div ref={panelRef} className={clsx(styles.panel, { [styles['panel-hidden']]: hidden })}>
          {application?.role &&
          (isOwner(globalRole) || isEditor(application?.role)) &&
          variant === 'admin' ? (
            <>
              <div className={styles['panel-top']}>
                <div
                  className={styles['app-icon-wrapper']}
                  style={{
                    backgroundImage: `url(${getImageURL(client?.avatar)})`,
                  }}
                >
                  {!client?.avatar && <AppIcon fill="#CED0D9" className={styles['app-icon']} />}
                </div>
                <div className={styles['name-wrapper']}>
                  <Typography
                    className={clsx(
                      'header-2-medium',
                      'font-golos',
                      'color-0B1641',
                      styles['overflow-ellipsis'],
                      styles['app-name'],
                    )}
                    component="div"
                  >
                    {client?.name}
                  </Typography>
                  <Popover
                    classes={{
                      paper: styles.paper,
                    }}
                    PaperProps={{
                      sx: {
                        transform: `translateX(${
                          290 - (anchorEl?.offsetWidth || 290)
                        }px) translateY(12px) !important`,
                      },
                    }}
                    onClose={handleClosePopover}
                    anchorEl={anchorEl}
                    open={isPopoverOpen}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <CustomPopoverButton
                      startIcon={<QuitIcon className={styles['action-button-icon']} />}
                      onClick={() => {
                        if (userId) {
                          handleDeleteSessionByClient(userId, selectedClientId);
                        }
                      }}
                    >
                      Завершить свои сеансы
                    </CustomPopoverButton>
                    <RouterLink
                      style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                      to={`/applications/${selectedClientId}`}
                    >
                      <CustomPopoverButton
                        startIcon={<GroupIcon className={styles['action-button-icon']} />}
                        style={{ marginTop: 8 }}
                        onClick={() => dispatch(setApplicationInfoTab(1))}
                      >
                        Участники приложения
                      </CustomPopoverButton>
                    </RouterLink>
                    <Box className={styles.divider} />
                    <RouterLink
                      style={{ display: 'block', width: '100%', textDecoration: 'none' }}
                      to={`applications/edit/${selectedClientId}`}
                    >
                      <CustomPopoverButton
                        startIcon={<EditIcon className={styles['action-button-icon']} />}
                      >
                        Настройки
                      </CustomPopoverButton>
                    </RouterLink>
                    {!isOwner(application?.role) && (
                      <>
                        <Box className={styles.divider} />
                        <CustomPopoverButton
                          onClick={() => setDeleteModalOpen(true)}
                          startIcon={<BasketIcon className={styles['action-button-icon']} />}
                          style={{ marginTop: 8 }}
                        >
                          Удалить
                        </CustomPopoverButton>
                      </>
                    )}
                  </Popover>
                  <div className={styles['user-buttons']}>
                    <Button
                      onClick={handleOpenPopover}
                      className={clsx('color-0B1641', 'text-14')}
                      variant="custom"
                      color="secondary"
                      endIcon={<ArrowDown fill="#0B1641" />}
                    >
                      Действия
                    </Button>
                  </div>
                </div>
                <a
                  style={{ marginLeft: 'auto', alignSelf: 'flex-start' }}
                  target="_blank"
                  rel="noreferrer"
                  href={`application/${client?.client_id}`}
                >
                  <Tooltip
                    placement="bottom-end"
                    arrow
                    title={'В новом окне'}
                    classes={{
                      tooltip: styles['input-tooltip'],
                      arrow: styles['input-tooltip-arrow'],
                    }}
                  >
                    <IconButton className={styles['go-over-button']}>
                      <GoOverIcon style={{ margin: 0 }} />
                    </IconButton>
                  </Tooltip>
                </a>
              </div>
              <ApplicationAdminTabs
                panelRef={panelRef}
                application={application}
                scopesData={scopesData}
                applicationRightPanelTab={applicationRightPanelTab}
              />
            </>
          ) : (
            <div style={{ height: '100%', overflow: 'hidden' }}>
              <ApplicationRightPanelProfile
                variant={variant}
                panelRef={panelRef}
                application={application}
                scopesData={scopesData}
              />
            </div>
          )}
        </div>
        <Modal open={deleteModalOpen} onClose={closeDeleteModal}>
          <div className={styles['delete-modal']}>
            <div style={{ display: 'flex' }}>
              <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
                Удалить приложение
              </Typography>
              <IconButton
                onClick={closeDeleteModal}
                style={{ marginLeft: 'auto', marginBottom: 16 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
              Приложение {client?.name} будет удалено.
              <br />
              Пользователи не смогут войти в ваше приложение, используя аккаунт {PROJECT_NAME}.
              <br />
              <br />
              Это действие невозможно отменить.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="custom" color="secondary" onClick={closeDeleteModal}>
                Отмена
              </Button>
              <Button
                onClick={handleDeleteApp}
                variant="custom"
                style={{ marginLeft: 24, background: '#FC545C' }}
              >
                Удалить
              </Button>
            </div>
          </div>
        </Modal>
      </ResizablePanel>
    );
  }
};

export const ApplicationRightPanel = connect(mapStateToProps)(ApplicationRightPanelComponent);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div style={index === 0 ? { height: '100%', overflow: 'hidden' } : {}} hidden={value !== index}>
      {value === index && children}
    </div>
  );
}

type ApplicationAdminTabsProps = {
  application?: TApplication;
  scopesData?: { scopes?: string; createdAt?: string };
  panelRef: RefObject<HTMLDivElement>;
  applicationRightPanelTab: number;
};

const ApplicationAdminTabs: FC<ApplicationAdminTabsProps> = ({
  application,
  panelRef,
  scopesData,
  applicationRightPanelTab,
}) => {
  const [usersCount, setUsersCount] = useState(0);
  const [getUserCount] = useLazyGetUserCountQuery();
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (typeof newValue === 'number') dispatch(setApplicationRightPanelTab(newValue));
  };
  useEffect(() => {
    const setUsersCountOnStart = async () => {
      const { data: dataCount } = await getUserCount({
        selectedAppId: application?.client.client_id || '',
        search_string: '',
      });
      setUsersCount(dataCount?.userCount || 0);
    };
    setUsersCountOnStart();
  }, [application]);

  return (
    <>
      <Tabs
        className={styles.tabs}
        TabIndicatorProps={{ className: styles['tab-indicator'] }}
        value={applicationRightPanelTab}
        onChange={handleChange}
      >
        <Tab label="Профиль" id="1" classes={{ root: styles.tab }} />
        <Tab label={`Участники ${usersCount}`} id="2" classes={{ root: styles.tab }} />
        {/* #293
        <Tab
          label="Приглашения"
          id="3"
          classes={{ root: styles.tab, selected: styles['tab-selected'] }}
        /> */}
      </Tabs>
      <TabPanel value={applicationRightPanelTab} index={0}>
        <ApplicationRightPanelProfile
          variant="admin"
          panelRef={panelRef}
          application={application}
          scopesData={scopesData}
        />
      </TabPanel>
      <TabPanel value={applicationRightPanelTab} index={1}>
        <ApplicationRightPanelUsers panelRef={panelRef} />
      </TabPanel>
      {/* #293
      <TabPanel value={applicationRightPanelTab} index={2}>
        Item Three
      </TabPanel> */}
    </>
  );
};
