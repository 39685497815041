import React, { useCallback, useState } from 'react';
import { Navigate, Outlet, RouteProps, useParams } from 'react-router-dom-v5-compat';
import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserRoleInApp, TUserProfile } from '../../redux/userSlice';
import { isEditor, isOwner, showDisplay } from '../../helpers';
import { getUserRoleInApp } from '../../requests/user';
import { Roles } from '../../enums';
import { TopTabsApplication } from '../applications/TopTabsApplication';

type OwnerOrEditorRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  userRole?: Roles;
  isUserProfileLoading: boolean;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  userRole: state.user.userProfile.role,
  isUserProfileLoading: state.user.isUserProfileLoading,
});

const OwnerOrEditorRouteComponent: React.FC<OwnerOrEditorRouteProps> = ({
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  userRole,
  isUserProfileLoading,
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isOwnerOrEditor, setIsOwnerOrEditor] = useState(false);
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' &&
    userId &&
    (passwordChangeRequired || !email || !birthdate);
  const { clientId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    let cleanupFunction = false;

    const getToken = async () => {
      try {
        const token = await getAccessToken();

        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
        } else {
          !cleanupFunction && setIsAuthorized(false);
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }
    };
    getToken();

    if (isOwner(userRole)) {
      !cleanupFunction && setIsOwnerOrEditor(true);
    } else {
      const getRole = async () => {
        if (userId && clientId) {
          const roleInApp = await getUserRoleInApp(userId || '', clientId);
          if (roleInApp && isEditor(roleInApp)) {
            dispatch(setUserRoleInApp(roleInApp));
            !cleanupFunction && setIsOwnerOrEditor(true);
          }
        } else {
          !cleanupFunction && setIsOwnerOrEditor(false);
        }
        if (!isUserProfileLoading) !cleanupFunction && setWait(false);
      };
      getRole();
    }

    return () => {
      cleanupFunction = true;
    };
  }, [location.pathname, isUserProfileLoading]);

  useEffect(() => {
    if (isAuthorized && isOwnerOrEditor) {
      setWait(false);
      showDisplay();
    }
  }, [isAuthorized, isOwnerOrEditor]);

  const OwnerOrEditorOutlet = useCallback(() => {
    if (isAuthorized && isOwnerOrEditor) {
      if (redirectToFillProfile) return <Navigate to="/fill-profile" replace />;

      return (
        <>
          <TopTabsApplication />
          <Outlet />
        </>
      );
    }
    return <Navigate to={'/applications'} replace />;
  }, [isAuthorized, isOwnerOrEditor, redirectToFillProfile]);

  return wait ? <LinearProgress /> : <OwnerOrEditorOutlet />;
};

export const OwnerOrEditorRoute = connect(mapStateToProps)(OwnerOrEditorRouteComponent);
