import React, { Dispatch, SetStateAction } from 'react';
import styles from './ChangePermissionsModal.module.css';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  TUser,
  clientApi,
  useGetSettingsQuery,
  useGetUserCountQuery,
} from '../../redux/services/client';
import { TAppSlice, setSelectedClientId, setToggleUpdateUsers } from '../../redux/appSlice';
import { changePermissionInApp } from '../../requests/client';
import { isAdministrator, isEditor, isOwner } from '../../helpers';
import { Roles } from '../../enums';
import { CLIENT_ID } from '../../constants';
import { CustomRadioButton } from '../CustomRadioButton';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { useNavigate } from 'react-router-dom-v5-compat';

type TChangePermissionsModal = {
  loggedUserId?: string;
  globalRole?: string;
  selectedClientId: TAppSlice['selectedClientId'];
  selectedUserToActions: Partial<TUser> | null;
  selectedUserRoleFromProps?: string;
  users?: ({ user: Partial<TUser>; role: string } | undefined)[];
  closeChangePermissionModal: () => void;
  selectedPermission: string | null;
  setSelectedPermission: Dispatch<SetStateAction<string | null>>;
  setChangePermissionsModalOpen: Dispatch<SetStateAction<boolean>>;
  toggleUpdateUsers: TAppSlice['toggleUpdateUsers'];
};

const mapStateToProps = (state: RootState) => ({
  loggedUserId: state.user.userProfile.id,
  globalRole: state.user.userProfile.role,
  toggleUpdateUsers: state.app.toggleUpdateUsers,
});

const ChangePermissionsModalComponent: React.FC<TChangePermissionsModal> = ({
  loggedUserId,
  globalRole,
  selectedClientId,
  selectedUserToActions,
  selectedUserRoleFromProps,
  users,
  toggleUpdateUsers,
  closeChangePermissionModal,
  selectedPermission,
  setSelectedPermission,
  setChangePermissionsModalOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = (role?: string) => isAdministrator(role) || isEditor(role);
  const { data: dataSettings } = useGetSettingsQuery();
  const { data: dataCount } = useGetUserCountQuery({
    selectedAppId: selectedClientId || '',
    search_string: '',
  });
  const selectedUserRole = selectedUserRoleFromProps
    ? selectedUserRoleFromProps
    : users?.find((user) => selectedUserToActions?.id === user?.user.id)?.role;

  return (
    <div className={styles['change-permission-modal']}>
      <div style={{ display: 'flex', marginBottom: 24 }}>
        <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
          Изменить полномочия
        </Typography>
        <IconButton onClick={closeChangePermissionModal} style={{ marginLeft: 'auto', padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography style={{ marginBottom: 16 }} className={clsx('text-14', 'color-0B1641')}>
        Выберите полномочия для пользователя:
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
        <Avatar className={styles.avatar}>
          <Typography className={clsx('text-14', 'color-FFF')}>
            {selectedUserToActions?.nickname
              ?.split(' ')
              .map((name: string) => name[0]?.toUpperCase())
              .join('')}
          </Typography>
        </Avatar>
        <Typography className={clsx('text-14', 'color-0B1641')}>
          {selectedUserToActions?.nickname}
        </Typography>
      </div>
      <Typography style={{ marginBottom: 16 }} className={clsx('text-15-medium', 'color-0B1641')}>
        Уровень полномочий
      </Typography>
      {!(dataSettings?.allow_everyone_to_create_applications && selectedClientId === CLIENT_ID) &&
        dataCount !== undefined &&
        !(isAdmin(selectedUserRole) && dataCount?.adminCount + dataCount?.ownerCount === 1) && (
          <>
            <CustomRadioButton
              label="Участник"
              checked={
                selectedPermission ? !isAdmin(selectedPermission) : !isAdmin(selectedUserRole)
              }
              onClick={() => {
                setSelectedPermission(Roles.USER);
              }}
            />
            <Typography
              style={{ marginLeft: 40, marginBottom: 16 }}
              className={clsx('text-12', 'color-858BA0')}
            >
              Имеет доступ в приложение и может просматривать профиль приложения
            </Typography>
          </>
        )}
      <CustomRadioButton
        label={
          selectedClientId === CLIENT_ID
            ? 'Администратор личного кабинета'
            : 'Администратор приложения'
        }
        checked={selectedPermission ? isAdmin(selectedPermission) : isAdmin(selectedUserRole)}
        onClick={() => {
          setSelectedPermission(selectedClientId === CLIENT_ID ? Roles.ADMIN : Roles.EDITOR);
        }}
      />
      <Typography
        style={{ marginLeft: 40, marginBottom: 32 }}
        className={clsx('text-12', 'color-858BA0')}
      >
        Имеет полный контроль над приложением, управляет администраторами и может просматривать
        персональные данные участников
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="custom" color="secondary" onClick={closeChangePermissionModal}>
          Отмена
        </Button>
        <Button
          onClick={async () => {
            if (
              selectedClientId &&
              selectedUserToActions?.id &&
              selectedPermission &&
              selectedUserRole !== selectedPermission
            ) {
              await changePermissionInApp(
                selectedClientId,
                selectedUserToActions.id,
                selectedPermission,
              );
              setSelectedPermission(null);
              setChangePermissionsModalOpen(false);
              dispatch(
                clientApi.endpoints.getApplicationById.initiate(
                  { client_id: selectedClientId, user_id: String(selectedUserToActions?.id) },
                  {
                    subscribe: false,
                    forceRefetch: true,
                  },
                ),
              );

              if (
                !isOwner(globalRole) &&
                selectedPermission === Roles.USER &&
                parseInt(loggedUserId as string, 10) === selectedUserToActions?.id
              ) {
                dispatch(setSelectedClientId(undefined));
                navigate('/applications');
              } else {
                dispatch(setToggleUpdateUsers(!toggleUpdateUsers));
              }
            }
          }}
          variant="custom"
          style={{ marginLeft: 24 }}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export const ChangePermissionsModal = connect(mapStateToProps)(ChangePermissionsModalComponent);
