import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as PasteProviderIcon } from '../icons/PasteProvider.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MiscProviderType, useCreateEthereumProviderMutation } from '../redux/services/provider';

import { isObjectEmpty, isOwner } from '../helpers';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';

export type CreateEthereumProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auto_registration?: boolean;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

type TCreateProvider = {
  isOpen: boolean;
  close: () => void;
  pathToAvatar: string;
};

export const CreateEthereumProvider: FC<TCreateProvider> = ({ isOpen, close, pathToAvatar }) => {
  const methods = useForm<CreateEthereumProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      auto_registration: false,
      password_required: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | null>(null);
  const watchDescription = watch('description');
  const [createEthereumProvider, createResult] = useCreateEthereumProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close();
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        CreateEthereumProviderInputs & { id: string; type: MiscProviderType.ETHEREUM }
      > = JSON.parse(text);
      const { type, avatar, ...restInputs } = provider || {};
      delete restInputs.id;

      if (type !== MiscProviderType.ETHEREUM) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', avatar);
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateEthereumProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateEthereumProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
      delete rest.auto_registration;
    }
    createEthereumProvider({
      body: {
        ...rest,
        avatar: typeof avatar === 'string' ? null : avatar,
      },
      client_id: clientId,
    });
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа Ethereum
            </Typography>
            <Tooltip
              arrow
              title={'Вставить настройки'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <IconButton
                onClick={() => {
                  setFields();
                }}
                className={styles['paste-provider-button']}
              >
                <PasteProviderIcon />
              </IconButton>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              pathToAvatar={pathToAvatar}
              setAvatarLink={setAvatarLink}
              setAvatarValue={setAvatarValue}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <ProviderFooter type={MiscProviderType.ETHEREUM} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading}
              variant="custom"
              type="submit"
            >
              Создать
            </Button>
          </div>
        </form>
      </FormProvider>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={clipboardModalOpen} onClose={closeClipboardModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Вставить настройки
            </Typography>
            <IconButton
              onClick={closeClipboardModal}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Скопированные настройки не подходят для ETHEREUM-провайдера.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                closeClipboardModal();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
