import { BACKEND_URL, CUSTOM_USER_FIELDS } from '../constants';
import { TCustomFields, TUserProfile } from '../redux/userSlice';
import { getAccessToken } from '../service/auth';
import { sendLogoutRequest } from '../requests/oidc';

export enum PrivateScopesMode {
  SET_PRIVATE = 'SET_PRIVATE',
  SET_PUBLIC = 'SET_PUBLIC',
  SET_AVAILABLE_ON_REQUEST = 'SET_AVAILABLE_ON_REQUEST',
}

export const getUserRoleInApp = async (
  userId: string,
  clientId: string,
): Promise<TUserProfile['role']> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(BACKEND_URL + '/api/user/v1/role/' + userId + '/' + clientId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    const { role } = await response.json();
    return role;
  } catch (e) {
    console.log('getUserRoleInApp error: ', e);
  }
};

export const checkAppsBeforeDelete = async (userId: string) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/check_apps_before_delete/${userId}`, {
      headers: { Authorization: 'Bearer ' + accessToken },
    });
    const responseJson = response.json();
    return responseJson;
  } catch (e) {
    console.log('checkAppsBeforeDelete error: ', e);
  }
};

export const deleteUser = async (userId: string) => {
  try {
    const accessToken = await getAccessToken();
    const deleteResponse = await fetch(`${BACKEND_URL}/api/user/v1/${userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      credentials: 'include',
    });
    if (deleteResponse.ok) sendLogoutRequest();
  } catch (e) {
    console.log('deleteUser error: ', e);
  }
};

export const checkCredentials = async (identifier: string, password: string) => {
  try {
    const body = 'identifier=' + identifier + '&password=' + password;
    let verified;

    const response = await fetch(`${BACKEND_URL}/api/auth/v1/check_credentials`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    if (response?.ok) ({ verified } = await response.json());

    return !!verified;
  } catch (e) {
    console.log('checkCredentials error: ', e);
  }
};

export const changePassword = async (newPassword: string, userId: string) => {
  try {
    const accessToken = await getAccessToken();
    await fetch(`${BACKEND_URL}/api/user/v1/password/${userId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: `${encodeURIComponent('password')}=${encodeURIComponent(newPassword)}`,
    });
    sendLogoutRequest();
  } catch (e) {
    console.log('changePassword error: ', e);
  }
};

export const updateUser = async (
  userParams: Partial<
    Omit<TUserProfile, 'picture'> & { picture: File | string | null } & TCustomFields
  >,
  userId: string,
) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${BACKEND_URL}/api/user/v1/update/${userId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      credentials: 'include',
      body: userParamsToFormData(userParams),
    });

    return response;
  } catch (e) {
    console.log('updateUser error: ', e);
  }
};

export const setPublicExternalAccount = async (
  mode: PrivateScopesMode,
  accountsIds: TUserProfile['public_external_accounts_ids'],
  userId: string,
) => {
  try {
    const accessToken = await getAccessToken();
    await fetch(`${BACKEND_URL}/api/user/v1/private_scopes/${userId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ mode, public_external_accounts_ids: accountsIds }),
    });
  } catch (e) {
    console.log('setPublicExternalAccount error: ', e);
  }
};

export const checkIdentifier = async (identifier: string) => {
  let isActive;
  try {
    const body = 'identifier=' + encodeURIComponent(identifier);

    const response = await fetch(BACKEND_URL + '/api/auth/v1/check_identifier', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });
    if (response) ({ is_active: isActive } = await response.json());

    return !!isActive;
  } catch (e) {
    console.log('checkIdentifier error: ', e);
  }
};

const userParamsToFormData = (
  requestParams: Partial<
    Omit<TUserProfile, 'picture'> & {
      picture?: File | string | null;
      provider_id: string;
      redirect_uri: string;
    } & TCustomFields
  >,
) => {
  try {
    const customFields: TCustomFields = {};

    const result = Object.keys(requestParams).reduce((acc, key) => {
      if (key === 'picture' && requestParams[key] !== undefined) {
        acc.append(key, requestParams[key] || '');
        return acc;
      }

      if (CUSTOM_USER_FIELDS && key in CUSTOM_USER_FIELDS) {
        if (typeof requestParams[key] === 'string')
          customFields[key] = requestParams[key] as string;
        return acc;
      }

      acc.append(key, requestParams[key]?.toString() || '');

      return acc;
    }, new FormData());

    if (customFields) result.append('custom_fields', JSON.stringify(customFields));

    return result;
  } catch (e) {
    console.log('userParamsToFormData error: ' + (e as Error).message);
  }
};
