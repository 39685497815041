import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import {
  MiscProviderType,
  OauthProviderType,
  useCreateQRCodeProviderMutation,
} from '../redux/services/provider';
import { CreateProvider } from './CreateProvider';
import { CreateProviderByTemplate } from './CreateProviderByTemplate';
import { BACKEND_URL } from '../constants';
import { CreateEthereumProvider } from './CreateEthereumProvider';
import { CreateSmsProvider } from './CreateSmsProvider';

//PRIVATE REPO
import { Create1CProvider } from './Create1CProvider';
import { CreateIdmProvider } from './CreateIdmProvider';
import { CreateLdapProvider } from './CreateLdapProvider';
import { useGetLicensesQuery } from '../redux/services/owner';
import { useParams } from 'react-router-dom-v5-compat';
//PRIVATE REPO END

type TCreateProvider = {
  isOpen: boolean;
  close: () => void;
};

export const ChooseProvider: FC<TCreateProvider> = ({ isOpen, close }) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [createQRCodeProvider] = useCreateQRCodeProviderMutation();

  const ldapProviderAvatar = 'public/images/provider/ldap.svg';
  const aldProProviderAvatar = 'public/images/provider/ald-pro.png';
  const _1CProviderAvatar = 'public/images/provider/1c.svg';
  const ethereumProviderAvatar = 'public/images/provider/ethereum.svg';
  const smsProviderAvatar = 'public/images/provider/sms.svg';
  const idmProviderAvatar = 'public/images/provider/idm.svg';

  const [providerTemplate, setProviderTemplate] = useState<{
    type: OauthProviderType | MiscProviderType;
    name: string;
    pathToAvatar: string;
  }>({
    type: OauthProviderType.CUSTOM,
    name: '',
    pathToAvatar: '',
  });

  const { data: licenses } = useGetLicensesQuery();
  const [isCreateFormTemplateOpen, setIsCreateFormTemplateOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isCreateFormLdapOpen, setIsCreateFormLdapOpen] = useState(false);
  const [isCreateFormAldProOpen, setIsCreateFormAldProOpen] = useState(false);
  const [isCreateForm1COpen, setIsCreateForm1COpen] = useState(false);
  const [isCreateFormIdmOpen, setIsCreateFormIdmOpen] = useState(false);
  const [isCreateFormEthereumOpen, setIsCreateFormEthereumOpen] = useState(false);
  const [isCreateFormSmsOpen, setIsCreateFormSmsOpen] = useState(false);

  const providers = [
    {
      type: OauthProviderType.YANDEX,
      name: 'Яндекс',
      pathToAvatar: 'public/images/provider/yandex.svg',
    },
    {
      type: OauthProviderType.VK,
      name: 'ВКонтакте',
      pathToAvatar: 'public/images/provider/vk.svg',
    },
    {
      type: OauthProviderType.MAILRU,
      name: 'Mail.ru',
      pathToAvatar: 'public/images/provider/mail.svg',
    },
    {
      type: OauthProviderType.GOOGLE,
      name: 'Google',
      pathToAvatar: 'public/images/provider/google.svg',
    },
    {
      type: MiscProviderType.KLOUD,
      name: 'Kloud.One',
      pathToAvatar: 'public/images/provider/kloud.svg',
    },
    {
      type: MiscProviderType.QRCODE,
      name: 'QR-код',
      pathToAvatar: 'public/images/provider/qrcode.svg',
    },
    {
      type: OauthProviderType.CUSTOM,
      name: '',
      pathToAvatar: '',
    },
    //PRIVATE REPO
    {
      type: MiscProviderType.LDAP,
      name: 'LDAP',
      pathToAvatar: ldapProviderAvatar,
      licenseRequired: true,
    },
    {
      type: MiscProviderType.ALDPRO,
      name: 'ALDPRO',
      pathToAvatar: aldProProviderAvatar,
      licenseRequired: true,
    },
    {
      type: MiscProviderType._1C,
      name: '1C',
      pathToAvatar: _1CProviderAvatar,
      licenseRequired: true,
    },
    {
      type: MiscProviderType.IDM,
      name: 'IDM',
      pathToAvatar: idmProviderAvatar,
      licenseRequired: true,
    },
    //PRIVATE REPO END
    {
      type: MiscProviderType.ETHEREUM,
      name: 'Ethereum',
      pathToAvatar: ethereumProviderAvatar,
    },
    {
      type: MiscProviderType.SMS,
      name: 'СМС',
      pathToAvatar: smsProviderAvatar,
    },
  ].filter((provider) => {
    if (!provider.licenseRequired) return true;
    return !!licenses?.length;
  });

  const handleClose = () => {
    close();
  };

  return (
    <>
      <Drawer
        classes={{ paper: styles['drawer-paper'] }}
        BackdropProps={{ className: styles.backdrop }}
        onClose={handleClose}
        open={isOpen}
        anchor="right"
        variant="temporary"
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа
            </Typography>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['choose-provider']}>
            <Typography
              className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
            >
              Выберите шаблон способа входа
            </Typography>
            {providers.map((provider, index) => {
              return (
                <ListItem
                  key={Date.now() + index}
                  className={styles.provider}
                  onClick={() => {
                    if (provider.type === OauthProviderType.CUSTOM) {
                      setIsCreateFormOpen(true);
                    } else if (provider.type === MiscProviderType.LDAP) {
                      setIsCreateFormLdapOpen(true);
                    } else if (provider.type === MiscProviderType.ALDPRO) {
                      setIsCreateFormAldProOpen(true);
                    } else if (provider.type === MiscProviderType.ETHEREUM) {
                      setIsCreateFormEthereumOpen(true);
                    } else if (provider.type === MiscProviderType.SMS) {
                      setIsCreateFormSmsOpen(true);
                    } else if (provider.type === MiscProviderType._1C) {
                      setIsCreateForm1COpen(true);
                    } else if (provider.type === MiscProviderType.IDM) {
                      setIsCreateFormIdmOpen(true);
                    } else if (provider.type === MiscProviderType.QRCODE) {
                      createQRCodeProvider({
                        body: {
                          name: 'QR-код',
                          client_id: clientId,
                          type: MiscProviderType.QRCODE,
                          avatar: provider.pathToAvatar,
                        },
                        client_id: clientId,
                      });
                    } else if (
                      provider.type !== MiscProviderType.CREDENTIALS &&
                      provider.type !== MiscProviderType.EMAIL
                    ) {
                      setProviderTemplate({
                        ...providerTemplate,
                        type: provider.type,
                        name: provider.name,
                        pathToAvatar: provider.pathToAvatar,
                      });
                      setIsCreateFormTemplateOpen(true);
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.pathToAvatar && <IdIcon />}
                  </div>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name || 'OpenID Connect'}
                  </Typography>
                </ListItem>
              );
            })}
          </div>
        </div>
      </Drawer>
      <CreateProviderByTemplate
        isOpen={isCreateFormTemplateOpen}
        close={() => setIsCreateFormTemplateOpen(false)}
        providerTemplate={providerTemplate}
      />
      <CreateProvider isOpen={isCreateFormOpen} close={() => setIsCreateFormOpen(false)} />
      {
        //PRIVATE REPO
      }
      <CreateLdapProvider
        pathToAvatar={ldapProviderAvatar}
        isOpen={isCreateFormLdapOpen}
        close={() => setIsCreateFormLdapOpen(false)}
        providerType={MiscProviderType.LDAP}
      />
      <CreateLdapProvider
        pathToAvatar={aldProProviderAvatar}
        isOpen={isCreateFormAldProOpen}
        close={() => setIsCreateFormAldProOpen(false)}
        providerType={MiscProviderType.ALDPRO}
      />
      <Create1CProvider
        pathToAvatar={_1CProviderAvatar}
        isOpen={isCreateForm1COpen}
        close={() => setIsCreateForm1COpen(false)}
      />
      <CreateIdmProvider
        pathToAvatar={idmProviderAvatar}
        isOpen={isCreateFormIdmOpen}
        close={() => setIsCreateFormIdmOpen(false)}
      />
      {
        //PRIVATE REPO END
      }
      <CreateEthereumProvider
        pathToAvatar={ethereumProviderAvatar}
        isOpen={isCreateFormEthereumOpen}
        close={() => setIsCreateFormEthereumOpen(false)}
      />
      <CreateSmsProvider
        pathToAvatar={smsProviderAvatar}
        isOpen={isCreateFormSmsOpen}
        close={() => setIsCreateFormSmsOpen(false)}
      />
    </>
  );
};
