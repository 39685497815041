import React from 'react';
import Typography from '@mui/material/Typography';
// #427 import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from './EditUserProfile.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { TCustomFields, TUserProfile } from '../../redux/userSlice';
import { updateUser, checkIdentifier } from '../../requests/user';
import { isDateError, isObjectEmpty } from '../../helpers';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { setIsModalOpen, setIsChangesUnsaved, setComponentPath } from '../../redux/appSlice';
import { TUser, useGetSettingsQuery, useLazyGetUsersQuery } from '../../redux/services/client';
import { CUSTOM_USER_FIELDS /* #427 PROJECT_NAME*/ } from '../../constants';
import { setNotice } from '../../redux/noticesSlice';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { ProfileFields } from './ProfileFields';
import { Roles } from '../../enums';

type Inputs = {
  given_name: string;
  picture: File | string | null;
  nickname: string;
  family_name: string;
  login: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
};

const schema = yup
  .object({
    nickname: yup
      .string()
      .max(71, 'Не может превышать 71 символ')
      .min(3, 'Не может быть меньше 3 символов')
      .matches(/^[^ ]+( *[^ ]+)*?$/, 'Не может содержать пробелы в начале и конце')
      .required('Обязательное поле'),
    given_name: yup
      .string()
      .max(50, 'Не может превышать 50 символов')
      .matches(
        /^([a-zA-Z0-9а-яА-ЯёЁ]+((\s|-|'|_|\.)[a-zA-Z0-9а-яА-ЯёЁ]+)*)?$/,
        "Имя может включать буквы (a-z) и (а-я),цифры (0-9) дефис (-),нижнее подчеркивание (_),апостроф ('), пробел и точку",
      ),
    family_name: yup
      .string()
      .max(50, 'Не может превышать 50 символов')
      .matches(
        /^([a-zA-Z0-9а-яА-ЯёЁ]+((\s|-|'|_|\.)[a-zA-Z0-9а-яА-ЯёЁ]+)*)?$/,
        "Фамилия может включать буквы (a-z) и (а-я),цифры (0-9) дефис (-),нижнее подчеркивание (_),апостроф ('), пробел и точку",
      ),
    login: yup
      .string()
      .min(3, 'Не может быть меньше 3 символов')
      .max(71, 'Не может превышать 71 символ')
      .matches(/^(?!\d+$).*$/, 'Логин не может состоять только из цифр')
      .matches(/^[^@]+$/, 'Логин не может содержать символ "@" ')
      .matches(/[^ ]+/, {
        message: 'Логин не может состоять только из пробелов',
      })
      // #584 .matches(/^[a-z0-9.]+$/, 'Может включать латинские буквы (a-z), цифры (0-9) и точку (.)')
      .required('Обязательное поле'),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TEditProfileComponent = {
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const EditProfileAsAdminComponent: FC<TEditProfileComponent> = ({
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const methods = useForm<Inputs & TCustomFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = methods;

  // #427 const [isPublic, setIsPublic] = useState<boolean>(false);
  const navigate = useNavigate();
  const watchBirthDay = watch('birthDay');
  const watchBirthMonth = watch('birthMonth');
  const watchBirthYear = watch('birthYear');
  const [hasBirthdate, setHasBirthdate] = useState(false);
  const months = [
    { name: 'январь', days: 31 },
    { name: 'февраль', days: +watchBirthYear % 4 === 0 ? 29 : 28 },
    { name: 'март', days: 31 },
    { name: 'апрель', days: 30 },
    { name: 'май', days: 31 },
    { name: 'июнь', days: 30 },
    { name: 'июль', days: 31 },
    { name: 'август', days: 31 },
    { name: 'сентябрь', days: 30 },
    { name: 'октябрь', days: 31 },
    { name: 'ноябрь', days: 30 },
    { name: 'декабрь', days: 31 },
  ];
  const selectedMonth = months.find((month) => month.name === watchBirthMonth);
  const dispatch = useDispatch();
  const [getUsers] = useLazyGetUsersQuery();
  const { data: dataSettings } = useGetSettingsQuery();
  const { clientId, userId } = useParams<{ clientId: string; userId: string }>();
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: Roles } | null | undefined
  >(null);

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId]);

  useEffect(() => {
    setValue('birthDay', String(Math.min(+(selectedMonth?.days || ''), +watchBirthDay)));
  }, [watchBirthMonth, watchBirthYear]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field)) return field.some((elem) => elem.value);
        return field === true;
      });
    if (isChangesUnsaved !== isDirty) dispatch(setIsChangesUnsaved(isDirty));
  }, [Object.values(dirtyFields)]);

  useEffect(() => {
    (
      ['nickname', 'given_name', 'family_name', 'login'] as Array<
        keyof Omit<Inputs, 'birthDay' | 'birthMonth' | 'birthYear' | 'picture'>
      >
    ).forEach((field) => {
      if (selectedUser?.user[field]) setValue(field, selectedUser?.user[field] || '');
    });
    if (selectedUser?.user.birthdate) {
      const date = new Date(selectedUser.user.birthdate);
      setValue('birthYear', String(date.getFullYear()));
      setValue('birthMonth', months[date.getMonth()]?.name);
      setValue('birthDay', String(date.getDate()));
      setHasBirthdate(true);
    }
    if (selectedUser?.user?.custom_fields) {
      const { custom_fields } = selectedUser.user;
      Object.keys(CUSTOM_USER_FIELDS)
        .filter((key) => custom_fields[key])
        .forEach((field) => {
          setValue(field, custom_fields[field] || '');
        });
    }
  }, [selectedUser?.user]);

  useEffect(() => {
    return () => {
      dispatch(setComponentPath('/profile'));
      setHasBirthdate(false);
    };
  }, []);

  const onSubmit: SubmitHandler<Inputs & TCustomFields> = async (data) => {
    let birthdate: null | Date = null;

    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (
        acc: Partial<
          Omit<TUserProfile, 'picture'> & { picture: File | string | null } & TCustomFields
        >,
        field,
      ) => {
        if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
          const month = months.findIndex(({ name }) => name === data.birthMonth);
          birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));
          return acc;
        }

        if (field === 'picture') {
          acc.picture = data.picture;
          return acc;
        }

        acc[field] = data[field];

        return acc;
      },
      {},
    );

    if ('login' in dirtyFields && selectedUser?.user.login !== data.login) {
      const isAvailable = await checkIdentifier(data.login);
      if (isAvailable) {
        setError('login', {
          type: 'api',
          message: 'Уже существует',
        });
        return;
      }
    }

    if (!userId) return;
    if (dateError) return;
    if (birthdate) payload.birthdate = (birthdate as unknown as Date).toISOString();

    const response = await updateUser(payload, String(userId));
    if (response?.ok) {
      navigate(`/applications/user/${clientId}/${userId}`);
      dispatch(setIsChangesUnsaved(false));
    } else {
      const responseJson = await response?.json();
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: responseJson?.message,
          timestamp: new Date().toString(),
        }),
      );
    }
  };

  const birthDate = new Date(
    +watchBirthYear,
    months.findIndex((month) => month.name === watchBirthMonth),
    +watchBirthDay,
  );
  const dateError = hasBirthdate && isDateError(birthDate, dataSettings);

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    navigate(componentPath);
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={
          isChangesUnsaved
            ? () => {
                dispatch(setIsModalOpen(true));
                dispatch(setComponentPath(`/applications/user/${clientId}/${userId}`));
              }
            : () => {
                dispatch(setComponentPath('/profile'));
                navigate(`/applications/user/${clientId}/${userId}`);
              }
        }
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Редактировать профиль
          </Typography>
          <ProfileFields userProfile={{ ...selectedUser?.user, role: selectedUser?.role }} />
          {/* #427
        <div className={styles.divider} />
        <div className={styles['padding-wrapper']}>
          <div className={styles['switch-wrapper']}>
            <div>
              <Typography
                className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
              >
                Публичность
              </Typography>
              <Typography style={{ width: 420 }} className={clsx('text-14', 'color-858BA0')}>
                Ваши публичное имя, id и фото профиля станут видны в поиске другим пользователям
                {PROJECT_NAME}
              </Typography>
            </div>
            <Switch
              value={isPublic}
              onChange={() => setIsPublic((isPublic) => !isPublic)}
              disableRipple
            />
          </div>
        </div> */}
        </form>
      </FormProvider>
      <Modal open={isModalOpen} onClose={() => dispatch(setIsModalOpen(false))}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="custom"
              color="secondary"
              onClick={() => dispatch(setIsModalOpen(false))}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                relocation();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const EditProfileAsAdmin = connect(mapStateToProps)(EditProfileAsAdminComponent);
