import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import styles from './AddPhoneBySms.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserProfile } from '../../redux/userSlice';
import { getUserProfile } from '../../requests/oidc';
import { convertUserProfile, isApiErrorWithMessage } from '../../helpers';
import {
  AccountTypes,
  useAddPhoneBySmsMutation,
  useGetExternalAccountsQuery,
} from '../../redux/services/user';
import {
  SmsCodeTypes,
  useLazyCheckPhoneNumberAvialabilityQuery,
  useSendSmsCodeMutation,
} from '../../redux/services/sms';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  nickname: state.user.userProfile.nickname,
});

type TAddPhoneBySmsComponent = {
  userId?: string;
  nickname?: string;
};

export const AddPhoneBySmsComponent: FC<TAddPhoneBySmsComponent> = ({ userId, nickname }) => {
  const { data: externalAccounts } = useGetExternalAccountsQuery(String(userId), {
    skip: !userId,
  });
  const [addPhoneBySms] = useAddPhoneBySmsMutation();
  const [checkPhoneNumberAvialability] = useLazyCheckPhoneNumberAvialabilityQuery();
  const { provider_id = '' } = useParams<{ provider_id: string }>();
  const [codeError, setCodeError] = useState('');
  const navigate = useNavigate();
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const dispatch = useDispatch();
  const [[minute, second], setTime] = useState([1, 30]);
  const [code, setCode] = useState('');
  const [externalAccountExists, setExternalAccountExists] = useState(false);
  const [sendSmsCode, { isLoading: sendSmsCodeLoading }] = useSendSmsCodeMutation();
  const phoneNumberWithCode = '+7' + phoneNumber;

  useEffect(() => {
    setTime([1, 30]);
  }, [isSmsSent]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const getPhoneNumberError = () => {
    const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phoneNumber) return 'Укажите номер телефона';
    if (
      externalAccounts?.find(
        (account) => account.sub === phoneNumberWithCode && account.type === AccountTypes.SMS,
      )
    )
      return 'Номер телефона уже привязан к вашему аккаунту';
    if (!phoneNumberTest.test(phoneNumber)) {
      return 'Неверный формат номера телефона';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    if (phoneNumberError) setPhoneNumberError('');
    if (externalAccountExists) setExternalAccountExists(false);
  };

  const getCodeError = () => {
    if (!code) return 'Укажите код подтверждения';
  };

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const smsInput = () => {
    setIsSmsSent((sent) => !sent); //Нужна какая то другая проверка
    setCodeError('');
    setCode('');
  };

  const handleSmsAction = async () => {
    if (getCodeError()) return;
    try {
      if (phoneNumber && userId) {
        const result = await addPhoneBySms({
          phoneNumber: phoneNumberWithCode,
          userId,
          code,
          rebind: externalAccountExists,
          provider_id,
        });

        if ('error' in result) {
          const { error } = result;
          if ('data' in error && isApiErrorWithMessage(error.data)) {
            return setCodeError(error.data.message);
          }
          return setCodeError('Неверный код подтверждения');
        }

        const profile = await getUserProfile();

        if (profile) {
          const convertedProfile = convertUserProfile(profile);
          dispatch(setUserProfile(convertedProfile));
        }
        navigate('/profile');
      }
    } catch (e) {
      console.log('handleSmsAction', e);
    }
  };

  const sendConfirmationCode = async (resend: boolean) => {
    try {
      if (getPhoneNumberError() || !userId) return;

      const response = await checkPhoneNumberAvialability(phoneNumberWithCode).unwrap();

      if (!externalAccountExists && !response.isAvailable) {
        setExternalAccountExists(true);
        return setPhoneNumberError(
          `Телефон уже используется ${
            //TODO: переделать после #487, undefined из autoRegistration
            response.nickname?.trim() === 'undefined' ? '' : 'пользователем ' + response.nickname
          }`,
        );
      }
      await sendSmsCode({
        type: SmsCodeTypes.addPhone,
        providerId: provider_id,
        userId,
        name: nickname,
        phoneNumber: phoneNumberWithCode,
        resend,
        rebind: externalAccountExists,
      })
        .unwrap()
        .catch((e) => {
          if (e?.data?.message === 'code.still.active') {
            return;
          }
          throw e;
        });

      setIsSmsSent(true);

      setTime([1, 30]);
    } catch (e) {
      console.log('sendConfirmationCodeError ', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        ></Typography>
        {!isSmsSent ? (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Номер телефона
            </Typography>
            <TextField
              value={phoneNumber}
              onChange={handleChange}
              className={clsx('custom', styles.textfield)}
              onBlur={() => {
                const phoneNumberErr = getPhoneNumberError();
                if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!phoneNumberError}
              helperText={phoneNumberError && !externalAccountExists ? phoneNumberError : ''}
              fullWidth
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumericFormatCustom as any,
              }}
              variant="standard"
            />
            {externalAccountExists && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ color: '#d32f2f' }} className={'text-14'}>
                  {phoneNumberError}
                </Typography>
                <Button
                  onClick={() => sendConfirmationCode(false)}
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                >
                  Перепривязать
                </Button>
              </div>
            )}
            <Typography className={clsx('text-14', 'color-858BA0')}>
              На этот номер телефона будет отправлен код подтверждения
            </Typography>
          </>
        ) : (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Введите код, отправленный на указанный номер телефона
            </Typography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (codeError) setCodeError('');
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              onBlur={() => {
                const codeErr = getCodeError();
                if (codeErr) setCodeError(codeErr);
              }}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <Typography className={clsx('text-14', 'color-858BA0')}>
                  Выслать код повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </Typography>
              ) : (
                <Button
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                  onClick={() => sendConfirmationCode(true)}
                >
                  Выслать код повторно
                </Button>
              )}
              <Button
                onClick={smsInput}
                variant="custom2"
                className={clsx('text-14')}
                style={{ padding: 0 }}
              >
                Изменить номер
              </Button>
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={sendSmsCodeLoading || (externalAccountExists && !isSmsSent)}
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={() => {
              if (isSmsSent) {
                return handleSmsAction();
              }
              sendConfirmationCode(false);
            }}
          >
            {isSmsSent ? 'Подтвердить' : 'Продолжить'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const NumericFormatCustom = React.forwardRef<
  PatternFormatProps,
  {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onPaste={(e: React.ClipboardEvent) => {
        const newValue = e.clipboardData.getData('text/plain')?.replace('+7', '');
        if (newValue.length === 10) {
          onChange({
            target: {
              name: props.name,
              value: newValue,
            },
          });
          e.preventDefault();
        }
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+7 (###) ###-####"
      mask="_"
      allowEmptyFormatting
    />
  );
});

export const AddPhoneBySms = connect(mapStateToProps)(AddPhoneBySmsComponent);
