import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { TUserProfile, TUserSlice, setUpdateExternalAccounts } from '../../redux/userSlice';
import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './UserProfile.module.css';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { exportToJson, getImageURL, getMonthByNumber } from '../../helpers';
import { useGetExternalAccountsQuery, useGetPrivateClaimsQuery } from '../../redux/services/user';
import { CUSTOM_USER_FIELDS, DATA_PROCESSING_POLICY_URL } from '../../constants';
import { EClaimPrivacy, PublicStatusPopover } from './PublicStatusPopover';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { ExternalAccount } from './ExternalAccount';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { ChangePasswordBlock } from './ChangePasswordBlock';

const mapStateToProps = ({ user }: RootState) => ({
  userProfile: user.userProfile,
  updateExternalAccounts: user.updateExternalAccounts,
});

type TUserProfileComponent = {
  userProfile: TUserSlice['userProfile'];
  updateExternalAccounts: boolean;
};

const UserProfileComponent: FC<TUserProfileComponent> = ({
  userProfile,
  updateExternalAccounts,
}) => {
  const date = userProfile.birthdate ? new Date(userProfile.birthdate) : null;
  const passwordUpdateDate = new Date(userProfile.password_updated_at || '');
  const navigate = useNavigate();
  const { data: externalAccounts, refetch } = useGetExternalAccountsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const { profile_claims_privacy, public_external_accounts_ids } = privateClaims || {};
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateExternalAccounts) {
      dispatch(setUpdateExternalAccounts(false));
      refetch();
    }
  }, []);

  const getClaimPrivacy = (claim: string): EClaimPrivacy => {
    const privacy = new RegExp(`(^${claim}|\\b${claim}):(\\w+)`, 'g').exec(
      profile_claims_privacy || '',
    )?.[2];

    return (privacy as EClaimPrivacy) || EClaimPrivacy.private;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography
          style={{ marginBottom: 32 }}
          className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}
        >
          Профиль
        </Typography>
        <Typography style={{ marginBottom: 14 }} className={clsx('text-14', 'color-858BA0')}>
          Вы можете управлять доступом к вашему аккаунту. Настройте какие данные будут предоставлены
          приложениям после вашего согласия.
        </Typography>
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <Typography className={clsx('header-3', 'font-golos')}>Основная информация</Typography>
            <Link
              to="/profile/edit"
              className={styles['margin-right']}
              style={{ marginLeft: 'auto', textDecoration: 'none' }}
            >
              <Button variant="custom2">Изменить</Button>
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  ID пользователя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.id}
                </Typography>
              </div>
              <PublicStatusPopover claimPrivacy={getClaimPrivacy('id')} claims="id" disabled />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичное имя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.nickname ? userProfile.nickname : 'Нет имени'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy('nickname')}
                claims="nickname"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Фото профиля
              </Typography>
              {userProfile.picture ? (
                <div
                  style={{
                    backgroundImage: `url(${getImageURL(userProfile?.picture)})`,
                  }}
                  className={styles['user-icon-wrapper']}
                />
              ) : (
                <Avatar className={styles.avatar}>
                  <AvatarIcon />
                </Avatar>
              )}
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy('picture')}
                claims="picture"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Имя и фамилия
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {(
                    (userProfile.given_name || '') +
                    ' ' +
                    (userProfile.family_name || '')
                  ).trim() || 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy('family_name')}
                claims="family_name given_name"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Логин
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.login || 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy('login')}
                claims="login"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Дата рождения
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {date
                    ? `0${date.getDate()}`.slice(-2) +
                      ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г.`
                    : 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy('birthdate')}
                claims="birthdate"
                userId={userProfile.id}
              />
            </div>
            {CUSTOM_USER_FIELDS &&
              Object.keys(CUSTOM_USER_FIELDS).map(
                (key) =>
                  userProfile[key as keyof TUserProfile] && (
                    <>
                      <div key={key} className={styles['info-item']}>
                        <Typography
                          className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                        >
                          {CUSTOM_USER_FIELDS?.[key]?.['title']}
                        </Typography>
                        <Typography
                          className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                        >
                          {userProfile[key as keyof TUserProfile]}
                        </Typography>
                        <PublicStatusPopover
                          claimPrivacy={getClaimPrivacy(key)}
                          claims={key}
                          userId={userProfile.id}
                        />
                      </div>
                    </>
                  ),
              )}
            {/* #427
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичность
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  Профиль не участвует в поиске
                </Typography>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <Typography className={clsx('text-17-regular', 'font-golos')}>Способы входа</Typography>
            <Button
              variant="custom2"
              className={styles['add-button']}
              onClick={() => navigate('/profile/external-provider')}
            >
              Добавить
            </Button>
          </div>
          <div className={styles.info}>
            {externalAccounts?.map((account) => (
              <ExternalAccount
                account={account}
                userProfile={userProfile}
                public_external_accounts_ids={public_external_accounts_ids}
                key={
                  (account.sub || '') +
                  (account.issuer || '') +
                  (account.type || '') +
                  (account.email || '')
                }
              />
            ))}
          </div>
        </div>
        <ChangePasswordBlock
          passwordUpdateDate={passwordUpdateDate}
          navigateTo="/profile/change-password"
        />
        <Accordion className={clsx(styles.panel, styles.accordion)}>
          <AccordionSummary
            className={styles['accorion-summary']}
            classes={{ content: styles['accorion-summary-content'] }}
            expandIcon={<ArrowDownIcon fill="#0B1641" />}
          >
            <Typography className={clsx('text-17-regular', 'font-golos')}>
              Другие действия
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            {/* #293
            <Button 
              variant="custom2"
              style={{ textAlign: 'left' }}
              className={styles['margin-bottom']}
            >
              {isAdministrator(userProfile.role)
                ? 'Отказаться от прав администратора'
                : 'Получить права администратора'}
            </Button> */}
            <Button
              variant="custom2"
              className={styles['margin-bottom']}
              onClick={async () => {
                await deleteAllSession(userProfile.id);
                window.location.reload();
              }}
            >
              Выйти со всех устройств
            </Button>
            <a href={DATA_PROCESSING_POLICY_URL}>
              <Button variant="custom2" className={styles['margin-bottom']}>
                Политика обработки ПДн
              </Button>
            </a>
            <Button
              onClick={() => exportToJson(userProfile, 'profile.json')}
              variant="custom2"
              className={styles['margin-bottom']}
            >
              Скачать данные
            </Button>
            {userProfile.id && parseInt(userProfile.id, 10) !== 1 && (
              <Button
                onClick={() => navigate('/profile/delete')}
                variant="custom2"
                className={styles['margin-bottom']}
              >
                Удалить аккаунт
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export const UserProfile = connect(mapStateToProps)(UserProfileComponent);
