import React, { FC, MouseEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import styles from './ApplicationUserInfo.module.css';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { EClaimPrivacy, PublicStatusPopover } from '../profile/PublicStatusPopover';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { RootState } from '../../redux/store';
import {
  useGetPrivateClaimsQuery,
  useGetPublicExternalAccountsQuery,
} from '../../redux/services/user';
import { TUser, useLazyGetUsersQuery } from '../../redux/services/client';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { TAppSlice } from '../../redux/appSlice';
import {
  setUpdateExternalAccounts,
  TUserSlice,
  TUserProfile,
  TCustomFields,
} from '../../redux/userSlice';
import {
  exportToJson,
  getImageURL,
  getMonthByNumber,
  isAdministrator,
  isEditor,
  isOwner,
  isOwnerOrEditor,
} from '../../helpers';
import { CLIENT_ID, CUSTOM_USER_FIELDS } from '../../constants';
import { Roles } from '../../enums';
import { ExternalAccount } from '../profile/ExternalAccount';
import { UserPopover } from '../profile/UserPopover';
import { ChangePermissionsModal } from './ChangePermissionsModal';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { ChangePasswordBlock } from '../profile/ChangePasswordBlock';

type TuseNavigateProps = {
  prevPath: string;
};

type TApplicationUserInfoComponent = {
  isMobile: TAppSlice['isMobile'];
  userRole?: string;
  updateExternalAccounts: boolean;
  userRoleInApp: TUserSlice['userRoleInApp'];
  rightPanel?: boolean;
  userIdProp?: number;
  clientIdProp?: string;
  loggedUserId: TUserProfile['id'];
  toggleUpdateUsers: TAppSlice['toggleUpdateUsers'];
};

const mapStateToProps = (state: RootState) => ({
  isMobile: state.app.isMobile,
  userRole: state.user.userProfile.role,
  updateExternalAccounts: state.user.updateExternalAccounts,
  userRoleInApp: state.user.userRoleInApp,
  loggedUserId: state.user.userProfile.id,
  toggleUpdateUsers: state.app.toggleUpdateUsers,
});
const ApplicationUserInfoComponent: FC<TApplicationUserInfoComponent> = ({
  userRole,
  isMobile,
  updateExternalAccounts,
  userRoleInApp,
  userIdProp,
  clientIdProp,
  rightPanel,
  loggedUserId,
  toggleUpdateUsers,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = useLocation();
  const getClientId = () =>
    currentPath.pathname.endsWith('/users') ? CLIENT_ID : String(clientIdProp);
  const { clientId = '', userId } = rightPanel
    ? { clientId: getClientId(), userId: userIdProp }
    : useParams<{ clientId: string; userId: string }>();

  const { prevPath } = (currentPath.state as TuseNavigateProps) || {
    prevPath: currentPath.pathname.startsWith(`/applications/user/${CLIENT_ID}`)
      ? '/applications/users'
      : `/applications/${clientId}`,
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);
  const [userCustomFields, setUserCustomFields] = useState<TCustomFields | null>(null);
  const [selectedPermission, setSelectedPermission] = useState<null | string>(null);
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const date = new Date(selectedUser?.user.birthdate || 0);
  const [getUsers] = useLazyGetUsersQuery();
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();
  const { data: externalAccounts, refetch } = useGetPublicExternalAccountsQuery(
    { user_id: String(userId), client_id: clientId },
    {
      skip: (!isOwner(userRole) && userRoleInApp !== Roles.EDITOR) || !userId,
    },
  );

  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userId), {
    skip: !isOwner(userRole) || !userId,
  });
  const { profile_claims_privacy, public_external_accounts_ids } = privateClaims || {};
  const isAdmin = () => isOwner(userRole) || isEditor(userRoleInApp);

  const closeChangePermissionModal = () => {
    setSelectedPermission(null);
    setChangePermissionsModalOpen(false);
  };

  useEffect(() => {
    const start = async () => {
      if (!userId) return;
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: String(userId),
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId, toggleUpdateUsers]);

  useEffect(() => {
    if (selectedUser?.user?.custom_fields) setUserCustomFields(selectedUser.user.custom_fields);

    return () => {
      setUserCustomFields(null);
    };
  }, [selectedUser]);

  useEffect(() => {
    if (updateExternalAccounts) {
      dispatch(setUpdateExternalAccounts(false));
      refetch();
    }
  }, []);

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => setAnchorEl(null);
  const name = (selectedUser?.user.nickname || '').trim()
    ? selectedUser?.user.nickname
    : ((selectedUser?.user.given_name || '') + ' ' + (selectedUser?.user.family_name || '')).trim();

  const getClaimPrivacy = (claim: string): EClaimPrivacy => {
    const privacy = new RegExp(`(^${claim}|\\b${claim}):(\\w+)`, 'g').exec(
      profile_claims_privacy || '',
    )?.[2];

    return (privacy as EClaimPrivacy) || EClaimPrivacy.private;
  };
  return (
    <div className={clsx(styles.wrapper, { [styles['panel-wrapper']]: rightPanel })}>
      <div className={clsx(styles.content, { [styles['panel-content']]: rightPanel })}>
        {!rightPanel && (
          <Button
            onClick={() => navigate(prevPath)}
            variant="custom3"
            className={clsx('text-15', styles['button-back'])}
            startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
          >
            {prevPath.endsWith('/applications')
              ? 'Приложения'
              : prevPath.startsWith(`/applications/${clientId}`)
              ? 'Приложение'
              : ' Пользователи'}
          </Button>
        )}
        <div className={styles['panel-top']}>
          <div
            className={styles['app-icon-wrapper']}
            style={{
              backgroundImage: `url(${getImageURL(selectedUser?.user?.picture)})`,
            }}
          >
            {!selectedUser?.user.picture && (
              <div className={styles['app-icon-default']}>
                {selectedUser?.user.nickname
                  ?.split(' ')
                  .map((name: string) => name[0]?.toUpperCase())
                  .join('')}
              </div>
            )}
          </div>
          <div className={styles['name-wrapper']}>
            <Typography
              className={clsx(
                'header-2-medium',
                'font-golos',
                'color-0B1641',
                styles['overflow-ellipsis'],
              )}
              component="div"
            >
              {!name && !selectedUser?.user?.profile_claims_privacy?.includes('nickname')
                ? 'Имя скрыто пользователем'
                : name || 'Нет имени'}
            </Typography>
            <UserPopover
              open={isPopoverOpen}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{
                paper: styles.paper,
              }}
              PaperProps={{
                sx: {
                  transform: `translateX(${
                    290 - (anchorEl?.offsetWidth || 290)
                  }px) translateY(12px) !important`,
                },
              }}
              userId={parseInt(userId as string, 10)}
              clientId={clientId}
              loggedUserId={loggedUserId}
              setChangePermissionsModalOpen={setChangePermissionsModalOpen}
            />
            {!isMobile && (
              <div className={styles['user-buttons']}>
                <Button
                  onClick={isOwnerOrEditor(userRole) ? handleOpenPopover : () => ''}
                  className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
                  variant="custom"
                  color="secondary"
                  endIcon={<ArrowDown fill="#0B1641" />}
                >
                  {isAdministrator(selectedUser?.role) || isEditor(selectedUser?.role)
                    ? 'Администратор'
                    : 'Участник'}
                </Button>
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <div className={styles['mobile-buttons']}>
            <Button
              onClick={isOwnerOrEditor(userRole) ? handleOpenPopover : () => ''}
              className={clsx('color-0B1641', 'text-14', styles['popover-button'])}
              variant="custom"
              color="secondary"
              endIcon={<ArrowDown fill="#0B1641" />}
            >
              {isOwnerOrEditor(selectedUser?.role) ? 'Администратор' : 'Участник'}
            </Button>
          </div>
        )}
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <Typography className={clsx('header-3', 'font-golos')}>Основная информация</Typography>
            {isOwner(userRole) && (
              <Link
                to={`/applications/user/edit/${clientId}/${userId}`}
                style={{ marginLeft: 'auto', textDecoration: 'none' }}
                className={styles['margin-right']}
              >
                <Button variant="custom2">Изменить</Button>
              </Link>
            )}
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  ID пользователя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {selectedUser?.user.id}
                </Typography>
              </div>
              {isOwner(userRole) && (
                <PublicStatusPopover claimPrivacy={EClaimPrivacy.public} claims="id" disabled />
              )}
            </div>
            {isAdmin() && !!(selectedUser?.user.nickname || '').trim() && (
              <div className={styles['info-item']}>
                <div className={styles['flex-wrap']}>
                  <Typography
                    className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                  >
                    Публичное имя
                  </Typography>
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                  >
                    {(selectedUser?.user.nickname || '').trim()
                      ? selectedUser?.user.nickname
                      : 'Нет имени'}
                  </Typography>
                </div>
                {isOwner(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy('nickname')}
                    claims="nickname"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {isAdmin() && !!selectedUser?.user.picture && (
              <div className={styles['info-item']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Фото профиля
                </Typography>
                {selectedUser?.user.picture ? (
                  <div
                    style={{
                      backgroundImage: `url(${getImageURL(selectedUser?.user?.picture)})`,
                    }}
                    className={styles['user-icon-wrapper']}
                  />
                ) : (
                  <Avatar className={styles.avatar}>
                    <AvatarIcon />
                  </Avatar>
                )}
                {isOwner(userRole) && (
                  <PublicStatusPopover
                    claimPrivacy={getClaimPrivacy('picture')}
                    claims="picture"
                    userId={String(selectedUser?.user.id)}
                  />
                )}
              </div>
            )}
            {isAdmin() && (
              <>
                {!!name && (
                  <div className={styles['info-item']}>
                    <div className={styles['flex-wrap']}>
                      <Typography
                        className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                      >
                        Имя и фамилия
                      </Typography>
                      <Typography
                        className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                      >
                        {name || 'Не задано'}
                      </Typography>
                    </div>
                    {isOwner(userRole) && (
                      <PublicStatusPopover
                        claimPrivacy={getClaimPrivacy('family_name')}
                        claims="family_name given_name"
                        userId={String(selectedUser?.user.id)}
                      />
                    )}
                  </div>
                )}
                {!!selectedUser?.user.login && (
                  <div className={styles['info-item']}>
                    <div className={styles['flex-wrap']}>
                      <Typography
                        className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                      >
                        Логин
                      </Typography>
                      <Typography
                        className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                      >
                        {selectedUser?.user.login || 'Не задано'}
                      </Typography>
                    </div>
                    {isOwner(userRole) && (
                      <PublicStatusPopover
                        claimPrivacy={getClaimPrivacy('login')}
                        claims="login"
                        userId={String(selectedUser?.user.id)}
                      />
                    )}
                  </div>
                )}
                {!!selectedUser?.user.birthdate && (
                  <div className={styles['info-item']}>
                    <div className={styles['flex-wrap']}>
                      <Typography
                        className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}
                      >
                        Дата рождения
                      </Typography>
                      <Typography
                        className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                      >
                        {selectedUser?.user.birthdate
                          ? `0${date.getDate()}`.slice(-2) +
                            ` ${getMonthByNumber(date.getMonth())} ${date.getFullYear()} г.`
                          : 'Не задано'}
                      </Typography>
                    </div>
                    {isOwner(userRole) && (
                      <PublicStatusPopover
                        claimPrivacy={getClaimPrivacy('birthdate')}
                        claims="birthdate"
                        userId={String(selectedUser?.user.id)}
                      />
                    )}
                  </div>
                )}
                {CUSTOM_USER_FIELDS &&
                  userCustomFields &&
                  Object.keys(CUSTOM_USER_FIELDS).map(
                    (key) =>
                      userCustomFields[key] && (
                        <>
                          <div className={styles['info-item']}>
                            <div className={styles['flex-wrap']}>
                              <Typography
                                className={clsx(
                                  'text-14',
                                  'color-858BA0',
                                  styles['info-item-title'],
                                )}
                              >
                                {CUSTOM_USER_FIELDS?.[key]?.['title']}
                              </Typography>
                              <Typography
                                className={clsx(
                                  'text-14',
                                  'color-0B1641',
                                  styles['info-item-value'],
                                )}
                              >
                                {userCustomFields[key]}
                              </Typography>
                            </div>
                            {isOwner(userRole) && (
                              <PublicStatusPopover
                                claimPrivacy={EClaimPrivacy.public}
                                claims={key}
                                userId={String(selectedUser?.user.id)}
                              />
                            )}
                          </div>
                        </>
                      ),
                  )}
              </>
            )}
            {/* #427
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичность
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  Профиль не участвует в поиске
                </Typography>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <Typography
              style={{ marginBottom: 24 }}
              className={clsx('text-17-regular', 'font-golos')}
            >
              Способы входа
            </Typography>
            {isOwner(userRole) && (
              <Button
                variant="custom2"
                className={styles['add-button']}
                onClick={() =>
                  navigate(`/applications/user/external-provider/${clientId}/${userId}`)
                }
              >
                Добавить
              </Button>
            )}
          </div>
          <div className={styles.info}>
            {isAdmin() &&
              selectedUser?.user &&
              externalAccounts?.map((account) => (
                <ExternalAccount
                  account={account}
                  userProfile={selectedUser.user}
                  public_external_accounts_ids={public_external_accounts_ids}
                  clientId={clientId}
                  toggleUpdateUsers={toggleUpdateUsers}
                  key={
                    (account.sub || '') +
                    (account.issuer || '') +
                    (account.type || '') +
                    (account.email || '')
                  }
                />
              ))}
          </div>
        </div>
        <ChangePasswordBlock
          passwordUpdateDate={new Date(selectedUser?.user.password_updated_at || '')}
          navigateTo="change-password"
        />
        <Accordion className={clsx(styles.panel, styles.accordion)}>
          <AccordionSummary
            className={styles['accorion-summary']}
            classes={{ content: styles['accorion-summary-content'] }}
            expandIcon={<ArrowDownIcon fill="#0B1641" />}
          >
            <Typography className={clsx('text-17-regular', 'font-golos')}>
              Другие действия
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <Button
              variant="custom2"
              className={styles['margin-bottom']}
              onClick={async () => {
                await deleteAllSession(selectedUser?.user.id);
              }}
            >
              Выйти со всех устройств
            </Button>
            {isOwner(userRole) && (
              <>
                <Button
                  onClick={() => exportToJson({ ...selectedUser }, 'profile.json')}
                  variant="custom2"
                  className={styles['margin-bottom']}
                >
                  Скачать данные
                </Button>
                {selectedUser?.user.id !== 1 &&
                  selectedUser?.user.id !== parseInt(loggedUserId as string, 10) && (
                    <Button
                      onClick={() => navigate(`/applications/user/delete/${clientId}/${userId}`)}
                      variant="custom2"
                      className={styles['margin-bottom']}
                    >
                      Удалить аккаунт
                    </Button>
                  )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal open={changePermissionsModalOpen} onClose={closeChangePermissionModal}>
        <ChangePermissionsModal
          selectedClientId={clientId}
          selectedUserToActions={selectedUser?.user as Partial<TUser> | null}
          selectedUserRoleFromProps={selectedUser?.role}
          closeChangePermissionModal={closeChangePermissionModal}
          selectedPermission={selectedPermission}
          setSelectedPermission={setSelectedPermission}
          setChangePermissionsModalOpen={setChangePermissionsModalOpen}
        />
      </Modal>
    </div>
  );
};

export const ApplicationUserInfo = connect(mapStateToProps)(ApplicationUserInfoComponent);
