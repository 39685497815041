import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from '../profile/EditProfile.module.css';
import yup from '../../customYup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { TCustomFields, TUserProfile, TUserSlice } from '../../redux/userSlice';
import { checkIdentifier } from '../../requests/user';
import {
  formatSpecChar,
  getMonthsByYear,
  isDateError,
  basicCharacterSet,
  checkForNumberEnding,
} from '../../helpers';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { setIsModalOpen, setIsChangesUnsaved, setComponentPath } from '../../redux/appSlice';
import { TSettings, useGetSettingsQuery } from '../../redux/services/client';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ProfileFields } from '../profile/ProfileFields';
import { EditProfileInputs, editProfileSchema } from '../profile/EditProfile';
import { useCreateUserMutation } from '../../redux/services/owner';
import { useLazyCheckEmailAvialabilityQuery } from '../../redux/services/mail';

export type UserInputs = EditProfileInputs & {
  password: string;
  repeat_password: string;
  email: string;
};

const schema = (settings: TSettings) =>
  editProfileSchema
    .shape({
      login: yup
        .string()
        .min(3, 'Не может быть меньше 3 символов')
        .max(71, 'Не может превышать 71 символ')
        .matches(/^(?!\d+$).*$/, 'Логин не может состоять только из цифр')
        .matches(/^[^@]+$/, 'Логин не может содержать символ "@" ')
        .matches(/[^ ]+/, {
          message: 'Логин не может состоять только из пробелов',
        })
        .required('Обязательное поле'),
      password: yup
        .string()
        .min(
          settings.length_char_min,
          `Пароль не может быть меньше ${settings.length_char_min} символов`,
        )
        .max(
          settings.length_char_max,
          `Пароль не может быть больше ${settings.length_char_max} символов`,
        )
        .matches(
          RegExp(
            `^(?=(.*[${formatSpecChar(settings.allowed_special_symbols)}]){${
              settings?.spec_char || 0
            }})(.+)[${basicCharacterSet(settings)}}]*$`,
          ),
          `Пароль должен содержать не менее ${settings.spec_char} ${
            settings.allowed_special_symbols
              ? `из следующих спецсимволов ${formatSpecChar(settings.allowed_special_symbols)}`
              : checkForNumberEnding(settings.spec_char)
              ? 'спецсимвола'
              : 'спецсимволов'
          }`,
        )
        .matches(
          RegExp(
            `^(?=(.*[${String(settings?.allowed_symbols || 'a-zа-яё').toUpperCase()}]){${
              settings?.min_uppercase_count || 0
            }})(.+)[${basicCharacterSet(settings)}}]*$`,
          ),
          `Пароль должен содержать не менее ${settings.min_uppercase_count} ${
            checkForNumberEnding(settings.min_uppercase_count)
              ? 'заглавной буквы'
              : 'заглавных букв'
          }`,
        )
        .matches(
          RegExp(`^(?=(.*[0-9]){${settings.number || 0}})(.+)[${6}}]*$`),
          `Пароль должен содержать не менее ${settings.number} ${
            checkForNumberEnding(settings.number) ? 'цифры' : 'цифр'
          }`,
        )
        .matches(
          RegExp(`^[${basicCharacterSet(settings)}]+$`),
          `Пароль должен содержать буквы ${settings.allowed_symbols}`,
        ),
      repeat_password: yup.string().equalTo(yup.ref('password'), 'Пароли не совпадают'),
      email: yup
        .string()
        .max(255, 'Адрес электронной почты не может превышать 255 символов')
        .email('Неверный формат адреса электронной почты'),
    })
    .required();

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  userId: state.user.userProfile.id,
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TCreateUserComponent = {
  userProfile: TUserSlice['userProfile'];
  userId?: string;
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const CreateUserComponent: FC<TCreateUserComponent> = ({
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const [checkIdentifierLoading, setCheckIdentifierLoading] = useState(false);
  const { data: dataSettings } = useGetSettingsQuery();
  const methods = useForm<UserInputs & TCustomFields>({
    resolver: yupResolver(schema((dataSettings || {}) as TSettings)),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = methods;
  const watchBirthYear = watch('birthYear');
  const dispatch = useDispatch();
  const months = getMonthsByYear(watchBirthYear);
  const navigate = useNavigate();
  const [createUser, { isLoading: createUserLoading }] = useCreateUserMutation();
  const [checkEmailAvialability, { isFetching: checkEmailFetching }] =
    useLazyCheckEmailAvialabilityQuery();

  useEffect(() => {
    if (dataSettings) {
      const date = new Date();
      setValue('birthYear', String(date.getFullYear() - dataSettings.min_age), {
        shouldDirty: false,
      });
      setValue('birthMonth', months[date.getMonth()]?.name, { shouldDirty: false });
      setValue('birthDay', String(date.getDate()), { shouldDirty: false });
    }
  }, [dataSettings]);

  const onSubmit: SubmitHandler<UserInputs & TCustomFields> = async (data) => {
    try {
      const month = months.findIndex(({ name }) => name === data.birthMonth);
      const birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));

      if (isDateError(birthdate, dataSettings)) return;

      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<Omit<TUserProfile, 'picture'> & { picture: File | null } & TCustomFields>,
          field,
        ) => {
          if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
            return acc;
          }

          if (field === 'picture') {
            if (typeof data.picture !== 'string') {
              acc.picture = data.picture;
              return acc;
            }

            return acc;
          }

          if (data[field]) acc[field] = data[field];

          return acc;
        },
        {},
      );

      if (birthdate) payload.birthdate = (birthdate as unknown as Date).toISOString();

      if ('login' in dirtyFields) {
        setCheckIdentifierLoading(true);
        const isAvailable = await checkIdentifier(data.login);
        setCheckIdentifierLoading(false);
        if (isAvailable) {
          setError('login', {
            type: 'api',
            message: 'Уже существует',
          });
          return;
        }
      }
      if ('email' in dirtyFields && data.email) {
        const response = await checkEmailAvialability(data.email).unwrap();

        if (!response.isAvailable) {
          setError('email', {
            type: 'api',
            message: 'Почтовый адрес уже используется',
          });
          return;
        }
      }
      await createUser(payload).unwrap();
      navigate('/applications/users');
      dispatch(setIsChangesUnsaved(false));
    } catch (e) {
      console.log('error:', e);
    }
  };

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    navigate(componentPath);
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={
          isChangesUnsaved
            ? () => {
                dispatch(setIsModalOpen(true));
                dispatch(setComponentPath('/applications/users'));
              }
            : () => {
                dispatch(setComponentPath('/applications/users'));
                navigate(componentPath);
              }
        }
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Пользователи
      </Button>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Создать пользователя
          </Typography>
          <ProfileFields
            isLoading={createUserLoading || checkEmailFetching || checkIdentifierLoading}
            createUser={true}
          />
        </form>
      </FormProvider>
      <Modal open={isModalOpen} onClose={() => dispatch(setIsModalOpen(false))}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="custom"
              color="secondary"
              onClick={() => dispatch(setIsModalOpen(false))}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                relocation();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const CreateUser = connect(mapStateToProps)(CreateUserComponent);
