import React, { useState } from 'react';
import styles from '../profile/ChangePassword.module.css';
import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { validatePassword } from '../../helpers';
import { useGetSettingsQuery } from '../../redux/services/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useChangePasswordByOwnerMutation } from '../../redux/services/owner';

export const ChangePasswordByOwner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<null | string>(null);
  const [repeatPasswordError, setRepeatPasswordError] = useState<null | string>(null);
  const [changePassword, { isLoading: changePasswordLoading }] = useChangePasswordByOwnerMutation();
  const { data: dataSettings } = useGetSettingsQuery();
  const { userId } = useParams();

  const getNewPasswordError = () => {
    if (!newPassword) return setNewPasswordError('Укажите новый пароль');
    const validationError = validatePassword(newPassword, dataSettings);
    if (validationError) {
      return setNewPasswordError(validationError);
    }
  };

  const getRepeatPasswordError = () => {
    if (!repeatPassword) return setRepeatPasswordError('Укажите новый пароль');
    if (repeatPassword !== newPassword) return setRepeatPasswordError('Пароли не совпадают');
  };

  const handleChangePassword = async () => {
    if (!newPasswordError && !repeatPasswordError && newPassword && repeatPassword && userId) {
      await changePassword({ newPassword, userId });
      navigate(location.pathname.replace('/change-password', ''));
    }
  };
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate(location.pathname.replace('/change-password', ''))}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-password']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Изменить пароль
        </Typography>
        <Typography style={{ marginBottom: 24 }} className={clsx('color-3C4567', 'text-14')}>
          После смены пароля произойдет выход из аккаунта на всех устройствах, где вы вошли с
          текущим паролем.
        </Typography>
        {userId && (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Новый пароль
            </Typography>
            <PasswordTextfield
              value={newPassword}
              onChange={(e) => {
                const { value } = e.target;
                if (newPasswordError) setNewPasswordError(null);
                if (value === repeatPassword && repeatPasswordError === 'Пароли не совпадают')
                  setRepeatPasswordError(null);
                setNewPassword(e.target.value);
              }}
              onBlur={getNewPasswordError}
              className={clsx('custom', styles['password-textfield'])}
              FormHelperTextProps={{
                className: clsx('color-FC545C', 'text-14'),
              }}
              error={!!newPasswordError}
              helperText={newPasswordError ? newPasswordError : ''}
              fullWidth
              variant="standard"
            />
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Повторите новый пароль
            </Typography>
            <PasswordTextfield
              value={repeatPassword}
              onChange={(e) => {
                if (repeatPasswordError) setRepeatPasswordError(null);
                setRepeatPassword(e.target.value);
              }}
              onBlur={getRepeatPasswordError}
              className={clsx('custom', styles['password-textfield'])}
              fullWidth
              variant="standard"
              FormHelperTextProps={{
                className: clsx('color-FC545C', 'text-14'),
              }}
              error={!!repeatPasswordError}
              helperText={repeatPasswordError ? repeatPasswordError : ''}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
              <Button onClick={() => navigate(-1)} variant="custom" color="secondary">
                Отмена
              </Button>
              <Button
                disabled={changePasswordLoading}
                onClick={handleChangePassword}
                variant="custom"
                style={{ marginLeft: 24 }}
              >
                Изменить
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
