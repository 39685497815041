import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as PasteProviderIcon } from '../icons/PasteProvider.svg';
import { ReactComponent as CopyIcon } from '../icons/Copy.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  OauthProviderType,
  TOauthProvider,
  useLazyGetSecretQuery,
  useUpdateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty } from '../helpers';
import { BACKEND_URL } from '../constants';
import { ScopeChips } from './ScopeChips';
import { TChips } from './CreateProvider';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { PasswordTextfield } from './custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';

export type EditProviderInputs = {
  name: string;
  description: string;
  external_client_id: string;
  external_client_secret: string;
  scopes: string;
  avatar: File | null;
  path_to_avatar: string;
  authorization_endpoint: string;
  token_endpoint: string;
  provider_id: string;
  issuer: string;
  userinfo_endpoint: string;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  mapping: string;
};

const schema = yup.object({
  name: yup
    .string()
    .max(50, 'Название не может превышать 50 символов')
    .required('Обязательное поле')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  authorization_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  token_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  userinfo_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  mapping: yup.string().max(2000, 'Mapping не может превышать 2000 символов'),
});

type TEditProvider = {
  isOpen: boolean;
  close: () => void;
  providerToEdit: Partial<TOauthProvider & { provider_id: string }> | null;
};

export const EditProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit }) => {
  const methods = useForm<EditProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, updateResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [chips, setChips] = useState<TChips[]>([]);
  const watchDescription = watch('description');
  const [getSecret] = useLazyGetSecretQuery();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateResult.isSuccess) close();
  }, [updateResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    const setSecret = async () => {
      const { data: clientSecret } = await getSecret({
        client_id: clientId,
        provider_id: providerToEdit?.id || '',
      });
      setValue('external_client_secret', clientSecret || '');
    };

    if (isOpen) setSecret();
    return () => {
      reset();
      setOverrideImage(null);
      setChips([]);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (
    provider?: Partial<TOauthProvider & { provider_id: string; isPublic: boolean }>,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TOauthProvider & {
              provider_id: string;
              isPublic: boolean;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, scopes, type, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.isPublic;

      if (typeof type === 'string' && type in OauthProviderType) {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (scopes)
          setChips(
            scopes
              ?.split(' ')
              .map((scope, index) => ({ value: scope, key: String(index), changing: false })) || [],
          );

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<EditProviderInputs, 'avatar' | 'scopes'>>
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      } else {
        setClipboardModalOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TOauthProvider, 'avatar'> & {
              avatar?: File | null;
              provider_id: string;
            }
          >,
          field,
        ) => {
          if (field === 'scopes') return acc;
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public'
          ) {
            acc[field] = data[field];
            return acc;
          }
          acc[field] = data[field];
          return acc;
        },
        {},
      );

      const scopes = chips.map((chip) => chip.value).join(' ');
      if (!isObjectEmpty(payload) || scopes !== providerToEdit.scopes)
        updateProvider({
          body: {
            ...payload,
            provider_id: providerToEdit.id,
            scopes,
            redirect_uri: BACKEND_URL + '/api/interaction/code',
            type: providerToEdit.type,
          },
          client_id: clientId,
        });
    }
  };
  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать способ входа
            </Typography>
            <Tooltip
              arrow
              title={'Вставить настройки'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <IconButton
                onClick={() => {
                  setFields();
                }}
                className={styles['paste-provider-button']}
              >
                <PasteProviderIcon />
              </IconButton>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Идентификатор ресурса (Client_id)
            </Typography>
            <TextField
              {...register('external_client_id', {
                required: true,
                onChange: () => {
                  if (errors.external_client_id) clearErrors('external_client_id');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Уникальный идентификатор подключаемого ресурса
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Cекретный ключ (client_secret)
            </Typography>
            <PasswordTextfield
              {...register('external_client_secret', {
                required: true,
                onChange: () => {
                  if (errors.external_client_secret) clearErrors('external_client_secret');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.external_client_secret}
              helperText={
                errors.external_client_secret ? errors.external_client_secret.message : ''
              }
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Cекретный ключ ресурса
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Базовый адрес сервера авторизации (issuer)
            </Typography>
            <TextField
              {...register('issuer', {
                required: true,
                onChange: () => {
                  if (errors.issuer) clearErrors('issuer');
                },
              })}
              style={{ marginBottom: 32 }}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.issuer}
              helperText={errors.issuer ? errors.issuer.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес авторизации (Аuthorization_endpoint)
            </Typography>
            <TextField
              {...register('authorization_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.authorization_endpoint) clearErrors('authorization_endpoint');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.authorization_endpoint}
              helperText={
                errors.authorization_endpoint ? errors.authorization_endpoint.message : ''
              }
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Адрес, на который пользователь переадресовывается для авторизации
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес выдачи токена (token_endpoint)
            </Typography>
            <TextField
              {...register('token_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.token_endpoint) clearErrors('token_endpoint');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.token_endpoint}
              helperText={errors.token_endpoint ? errors.token_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Ресурс, обеспечивающий выдачу токенов
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес получения информации о пользователе (userinfo_endpoint)
            </Typography>
            <TextField
              {...register('userinfo_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.userinfo_endpoint) clearErrors('userinfo_endpoint');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.userinfo_endpoint}
              helperText={errors.userinfo_endpoint ? errors.userinfo_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Ресурс, который возвращает информацию о текущем пользователе
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Запрашиваемые разрешения
            </Typography>
            <ScopeChips chips={chips} setChips={setChips} />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Перечень разрешений, которые должны быть получены при обращении к поставщику
              идентификации. Для добавления разрешения введите его имя и нажмите Enter.
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              ProviderId
            </Typography>
            <TextField
              value={providerToEdit?.id || ''}
              disabled
              className="custom"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      arrow
                      title="Копировать"
                      classes={{
                        tooltip: styles['input-tooltip'],
                        arrow: styles['input-tooltip-arrow'],
                      }}
                    >
                      <Button
                        className={styles['input-adornment-button']}
                        onClick={() => {
                          navigator.clipboard.writeText(providerToEdit?.id || '');
                        }}
                      >
                        <CopyIcon />
                      </Button>
                    </Tooltip>
                  </InputAdornment>
                ),
                classes: {
                  disabled: styles['input-wrapper-disabled'],
                },
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Уникальный идентификатор способа входа
            </Typography>
            {providerToEdit?.type === OauthProviderType.CUSTOM && (
              <>
                <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                  Mapping
                </Typography>
                <TextField
                  {...register('mapping', {
                    onChange: () => {
                      if (errors.mapping) clearErrors('mapping');
                    },
                  })}
                  className="custom"
                  FormHelperTextProps={{
                    className: clsx('text-14', 'color-858BA0'),
                  }}
                  error={!!errors.mapping}
                  helperText={errors.mapping ? errors.mapping.message : ''}
                  fullWidth
                  variant="standard"
                />
                <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
                  Сопоставление атрибутов
                </Typography>
              </>
            )}
            <ProviderFooter type={providerToEdit?.type as OauthProviderType} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={updateResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </FormProvider>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={clipboardModalOpen} onClose={closeClipboardModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Вставить настройки
            </Typography>
            <IconButton
              onClick={closeClipboardModal}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Скопированные настройки не подходят для Oauth-провайдера.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                closeClipboardModal();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
