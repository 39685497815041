import { providerParamsToFormData } from '../../helpers';
import { providerApi, TMiscProvider } from './provider';

export const _1CProviderApi = providerApi.injectEndpoints({
  endpoints: (builder) => ({
    create1CProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TMiscProvider, 'avatar' | 'type'> & {
            avatar?: File | null;
            provider_id: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `create_1c/${client_id}`,
        method: 'POST',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    update1CProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TMiscProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `update_1c/${client_id}`,
        method: 'PUT',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),
  }),
});

export const { useCreate1CProviderMutation, useUpdate1CProviderMutation } = _1CProviderApi;
