import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, RouteProps } from 'react-router-dom-v5-compat';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';
import { isAdministrator, showDisplay } from '../../helpers';
import { Roles } from '../../enums';
import { useGetSettingsQuery } from '../../redux/services/client';
import { TopTabsProfile } from '../profile/TopTabsProfile';

type PrivateRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  deleted: TUserProfile['deleted'];
  userRole?: Roles;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  deleted: state.user.userProfile.deleted,
  userRole: state.user.userProfile.role,
});

const PrivateRouteComponent: React.FC<PrivateRouteProps> = ({
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  deleted,
  userRole,
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { data: generalSettings, isFetching: generalSettingsFetching } = useGetSettingsQuery();
  const location = useLocation();
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' &&
    location.pathname !== '/profile/restore-profile' &&
    userId &&
    (passwordChangeRequired || !email || !birthdate);
  const redirectToRestoreUser =
    location.pathname !== '/profile/restore-profile' && userId && deleted;
  const canAuthorize = !(
    generalSettings?.authorize_only_admins &&
    userRole &&
    !isAdministrator(userRole)
  );

  useEffect(() => {
    let cleanupFunction = false;

    const getToken = async () => {
      try {
        const token = await getAccessToken();

        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
          !cleanupFunction && showDisplay();
        } else {
          !cleanupFunction && setIsAuthorized(false);
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }

      !cleanupFunction && setWait(false);
    };
    getToken();

    return () => {
      cleanupFunction = true;
    };
  }, [location.pathname]);

  const PrivateOutlet = () => {
    if (isAuthorized) {
      if (!canAuthorize && !generalSettingsFetching)
        return (
          <Navigate
            to="error/Вход в личный кабинет разрешён только пользователям с ролями «владелец» и «администратор»."
            replace
          />
        );
      if (redirectToRestoreUser) return <Navigate to="restore-profile" replace />;
      if (redirectToFillProfile) return <Navigate to="/fill-profile" replace />;
      return (
        <>
          <TopTabsProfile />
          <Outlet />
        </>
      );
    }

    return <Navigate to="/login" replace />;
  };

  return wait ? <LinearProgress /> : <PrivateOutlet />;
};

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
