import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as PasteProviderIcon } from '../icons/PasteProvider.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { MiscProviderType, TLdapParams, TMiscProvider } from '../redux/services/provider';
import { isObjectEmpty } from '../helpers';
import { ProviderHeader } from './ProviderHeader';
import { useUpdateLdapProviderMutation } from '../redux/services/ldapProvider';
import { ProviderFooter } from './ProviderFooter';
import { ProviderColor, TProviderColors } from './ProviderColor';
import Switch from '@mui/material/Switch';
import { useLazyGetAdminCredentialsQuery } from '../redux/services/ldap';
import { PasswordTextfield } from './custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';

const ldapParams = [
  'url',
  'base',
  'domain',
  'mapping',
  'search_filter',
  'admin_login',
  'admin_password',
];

export type EditLdapProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  url: string;
  base: string;
  domain: string;
  search_filter: string;
  mapping: string;
  admin_login: string;
  admin_password: string;
  provider_colors: TProviderColors;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  provider_title: string;
  show_provider_avatar: boolean;
  disable_password_reset: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  url: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес сервера LDAP не может превышать 255 символов')
    .matches(/^[^\n ]*$/, {
      message: 'Адрес не может содержать пробелы',
    })
    .matches(/ldaps?:\/\//, {
      excludeEmptyString: true,
      message: 'Адрес должен начинаться с ldap:// или ldaps://',
    }),
  base: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'База поиска не может превышать 255 символов')
    .matches(/^[^\n ]*$/, {
      message: 'База поиска не может содержать пробелы',
    }),
  domain: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Имя домена не может превышать 255 символов')
    .matches(
      /^[a-z0-9.-]+$/,
      'Имя может содержать только буквы (a-z), числовые символы (0-9), знак минуса (-) и точку (.)',
    ),
  mapping: yup.string().max(1024, 'Поле не может превышать 1024 символов'),
  admin_login: yup.string().required('Обязательное поле'),
  admin_password: yup
    .string()
    .required('Обязательное поле')
    .min(6, 'Пароль должен быть минимум 6 символов')
    .max(255, 'Пароль не может превышать 255 символов'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  disable_password_reset: yup.boolean(),
  is_public: yup.boolean(),
  provider_colors: yup.object({
    button_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
    font_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
  }),
});

type TEditProvider = {
  isOpen: boolean;
  close: () => void;
  providerToEdit: Partial<TMiscProvider & { provider_id: string }> | null;
};

export const EditLdapProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit }) => {
  const methods = useForm<EditLdapProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateLdapProvider, updateLdapResult] = useUpdateLdapProviderMutation();
  const [getAdminСredentials] = useLazyGetAdminCredentialsQuery();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const showAvatar = useWatch({ control, name: 'show_provider_avatar' });

  useEffect(() => {
    if (updateLdapResult.isSuccess) close();
  }, [updateLdapResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    const setAdminСredentials = async () => {
      const { data: credentials } = await getAdminСredentials({
        client_id: clientId,
        provider_id: providerToEdit?.id || '',
      });
      setValue('admin_login', credentials?.admin_login || '');
      setValue('admin_password', credentials?.admin_password || '');
    };

    if (isOpen) setAdminСredentials();
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (
    provider?: Partial<TMiscProvider & { provider_id: string; isPublic: boolean }>,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              provider_id: string;
              isPublic: boolean;
              client_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }

      const { avatar, params, type, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.isPublic;
      delete restInputs.client_id;

      if (type !== MiscProviderType.LDAP && type !== MiscProviderType.ALDPRO) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (params) {
          (Object.keys(params) as Array<keyof TLdapParams>).forEach((field) => {
            setValue(field, (params as TLdapParams)?.[field] || '', { shouldDirty: !provider });
          });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<EditLdapProviderInputs, keyof TLdapParams | 'avatar'>
            >
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public' ||
              field === 'show_provider_avatar' ||
              field === 'disable_password_reset'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            if (field === 'provider_colors') {
              return setValue(
                field,
                { ...(restInputs?.[field] as TProviderColors) },
                { shouldDirty: !provider },
              );
            }

            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditLdapProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar' | 'params'> & {
              avatar?: File | null;
              provider_id: string;
            } & TLdapParams
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public' ||
            field === 'show_provider_avatar' ||
            field === 'disable_password_reset'
          ) {
            acc[field] = data[field];
            return acc;
          }

          if (field === 'provider_colors') {
            acc[field] = data[field];
            return acc;
          }

          if (ldapParams.includes(field)) {
            (ldapParams as Array<keyof TLdapParams>).forEach((param) => {
              acc[param] = data[param];
            });
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateLdapProvider({
          body: {
            ...payload,
            provider_id: providerToEdit.id,
            type: providerToEdit.type,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать способ входа LDAP
            </Typography>
            <Tooltip
              arrow
              title={'Вставить настройки'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <IconButton
                onClick={() => {
                  setFields();
                }}
                className={styles['paste-provider-button']}
              >
                <PasteProviderIcon />
              </IconButton>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес сервера LDAP (ldap_url)
            </Typography>
            <TextField
              {...register('url', {
                required: true,
                onBlur: (event: FocusEvent<HTMLInputElement>) => {
                  setValue('url', event.target.value.trim());
                },
                onChange: () => {
                  if (errors.url) clearErrors('url');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.url}
              helperText={errors.url ? errors.url.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Адрес сервера LDAP в формате ldap://example.com
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              База поиска (ldap_base)
            </Typography>
            <TextField
              {...register('base', {
                required: true,
                onBlur: (event: FocusEvent<HTMLInputElement>) => {
                  setValue('base', event.target.value.trim());
                },
                onChange: () => {
                  if (errors.base) clearErrors('base');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.base}
              helperText={errors.base ? errors.base.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Объект каталога, начиная с которого будет производиться поиск
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Домен LDAP (ldap_domain)
            </Typography>
            <TextField
              {...register('domain', {
                required: true,
                onChange: () => {
                  if (errors.domain) clearErrors('domain');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.domain}
              helperText={errors.domain ? errors.domain.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Имя домена, которому принадлежат пользователи
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Фильтр поиска (ldap_filter)
            </Typography>
            <TextField
              {...register('search_filter', {
                onChange: () => {
                  if (errors.search_filter) clearErrors('search_filter');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.search_filter}
              helperText={errors.search_filter ? errors.search_filter.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Фильтр для поиска учетной записи пользователя
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Сопоставление атрибутов LDAP (ldap_mapping)
            </Typography>
            <TextField
              {...register('mapping', {
                required: true,
                onChange: () => {
                  if (errors.mapping) clearErrors('mapping');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.mapping}
              helperText={errors.mapping ? errors.mapping.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Сопоставление атрибутов в формате: sub:dn, given_name:givenName, family_name:sn,
              email:mail, login: userPrincipalName (маппинг на поле логин работает только при
              авторегистрации)
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Логин администратора (ldap_admin_dn)
            </Typography>
            <TextField
              {...register('admin_login', {
                required: true,
                onChange: () => {
                  if (errors.admin_login) clearErrors('admin_login');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.admin_login}
              helperText={errors.admin_login ? errors.admin_login.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Логин администратора LDAP
            </Typography>

            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Пароль администратора (ldap_admin_pwd)
            </Typography>
            <PasswordTextfield
              {...register('admin_password', {
                required: true,
                onChange: () => {
                  if (errors.admin_password) clearErrors('admin_password');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.admin_password}
              helperText={errors.admin_password ? errors.admin_password.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Пароль администратора LDAP
            </Typography>
            <ProviderFooter type={providerToEdit?.type as MiscProviderType} clientId={clientId} />
            <div className={styles['switch-wrapper']}>
              <Typography className={clsx('text-14', 'color-0B1641')}>
                Показывать логотип LDAP в виджете
              </Typography>
              <Switch
                checked={showAvatar}
                {...register('show_provider_avatar', {
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    setValue('show_provider_avatar', event.target.checked, { shouldDirty: true });
                  },
                })}
              />
            </div>
            <ProviderColor avatarSrc={avatarSrc} providerName={providerToEdit?.name} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={updateLdapResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </FormProvider>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={clipboardModalOpen} onClose={closeClipboardModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Вставить настройки
            </Typography>
            <IconButton
              onClick={closeClipboardModal}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Скопированные настройки не подходят для LDAP-провайдера.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                closeClipboardModal();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
